@import '../../../../common/common.scss';

.sponsors-section {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  background-color: #e5f6f8;
}

.sponsors-left-container {
  width: 50%;
  position: relative;
  background-color: #13819C;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsor-top-left-arrorw
{
  position: absolute;
  top: 0;
  left: 0;
  border-top: 50px solid #e5f6f8;
  border-left: 50px solid #e5f6f8;
  border-bottom: 50px solid transparent;
  border-right: 50px solid transparent;
}
.sponsor-bottom-right-arrorw
{
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 50px solid #e5f6f8;
  border-right: 50px solid #e5f6f8;
  border-top: 50px solid transparent;
  border-left: 50px solid transparent;
}

.sponsors-right-container {
  width: 50%;
  height: 100%;
  padding: 3.75rem 1.25rem;
  align-self: center;
  position: relative;
  margin-top: 100px;
  background-color: #FFFFFF;
}
.sponsors-right-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &__logo {
    img {
      width: 100%;
    }
  }

  &__logos {
    img {
      width: 180px;
    }
  }

  &__logo-wrapper1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3.25rem;
    place-items: center;
    padding-top: 4rem;
  }

  &__logo-wrapper2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3.25rem;
    place-items: center;
    padding-top: 4rem;
  }

  &__logo-wrapper3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3.25rem;
    place-items: center;
    padding-top: 4rem;
  }

  &__logo-wrapper4 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3.25rem;
    place-items: center;
    padding-top: 4rem;
  }

  &__logo-wrapper5 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3.25rem;
    place-items: center;
    padding-top: 4rem;
  }
  &__logo-wrapper6 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3.25rem;
    place-items: center;
    padding-top: 4rem;
  }
  &__logo-wrapper7 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3.25rem;
    place-items: center;
    padding-top: 4rem;
  }
  &__logo {
    img {
      width: 100%;
    }
  }

  &__logos {
    img {
      width: 180px;
    }
  }

  //Alice carousel
  .alice-carousel {
    &__dots {
      margin: 50px 3px 5px !important;
      .__active {
        background-color: $gray-1 !important;
      }
    }

    &__dots-item {
      background-color: rgba($color: $gray-1, $alpha: 0.3) !important;
      width: 90px !important;
      height: 5px !important;
      border-radius: 0 !important;
    }

    &__next-btn {
      display: none !important;
    }

    &__prev-btn {
      display: none !important;
    }
  }
}

.sponsors-right-img-wrapper {
  flex-basis: 225px;
  padding: 2.5rem 1.25rem;
  text-align: center;
  width: 225px;
  margin-left: auto;
  margin-right: auto;
}

.sponsors-left-content {
  margin-left: 9.75rem;
  margin-right: 6.25rem;
  margin-top: auto;
  margin-bottom: auto;
}

.sponsors-left-content_header {
  /*becomes label--secondary*/
  font-size: 0.6875rem;
  line-height: 1rem;
  letter-spacing: 0.04375rem;
}
.sponsors-left-content_title {
  margin-top: 1.5rem;
}

.sponsors-left-content__img {
  width: 70%;
  margin-top: 3rem;
  margin-bottom: 7.75rem;
}

@media screen and (max-width: 990px) {
  .sponsors-section {
    flex-direction: column;
  }
  .sponsors-right-container
  {
    margin-top: 0;
  }
  .sponsors-left-container,
  .sponsors-right-container {
    width: 100%;
    padding-bottom: 4rem;
    padding-top: 3rem;
  }
  .sponsors-left-content {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 3rem;
  }
  .sponsors-left-content__img {
    margin-bottom: 2rem;
  }
  .sponsor-top-left-arrorw,
  .sponsor-bottom-right-arrorw
  {
    display: none;
  }
}

.liz-logo {
  img {
    // height: 50px;
  }
}
@media screen and (max-width: 765px) {
  .sponsors-right-img-wrapper {
    flex-basis: 50%;
  }
  .sponsors-right-container {
    //margin-bottom: 0; // the empty 15th item provides sufficient margin
  }
}
@media screen and (max-width: 525px) {
  .sponsors-right-img-wrapper {
    flex-basis: 100%;
  }
  .sponsors-right-container {
    //margin-bottom: 0; // the empty 15th item provides sufficient margin
  }
}

@media screen and (min-width: 990px) {
  .sponsors-right-content {
    &__logo-wrapper1 {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      // padding-top: 12rem;
    }
    &__logo-wrapper2 {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    &__logo-wrapper3 {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    &__logo-wrapper4 {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      // padding-top: 12rem;
    }
    &__logo-wrapper5 {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      // padding-top: 12rem;
    }
    &__logo-wrapper6 {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      // padding-top: 12rem;
    }
    &__logo-wrapper7 {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      // padding-top: 12rem;
    }
  }
}

// @media screen and (min-width: 1440px) {
//   .sponsors-right-content {
//     &__logo-wrapper1 {
//       grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     &__logo-wrapper2 {
//       grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }

//     &__logo-wrapper3 {
//       grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }
//   }
// }

// @media screen and (min-width: 1660px) {
//   .sponsors-right-content {
//     &__logo-wrapper1 {
//       grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     }
//   }
//}
