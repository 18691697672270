/* 
    px units used because everything is placed relative to an image
    transform: scale() should be used to resize the si-wrapper as needed    
*/
/* .speakers-container */
.si-container {
    position: relative;          
    margin-left: auto;
    margin-right: auto;
    /* max-width: calc(100vw / 4); */

    /* height: calc(740px + 16px); */
    /* height: 560px; */
    padding: 40px;
    min-width: 320px;
    height: 480px;
    /* width: 22vw; */
    /* padding: 40px; */
}
.si-wrapper {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;    
}
.si-wrapper--has-modal {
    cursor: pointer;
}

.si-bg-vector {
    position: relative;    
}

.si-speaker {
    /* applied to container of speaker's headshot */
    position: absolute;
    width: 320px;
    /* left: 50px;
    top: 53px; */
}


.slick-slide img
{
    width: 100%;
}

.si-container:hover > .si-speaker:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(252, 84, 32,0), rgba(252, 84, 32,0.3));
    cursor: pointer;
}
.si-speaker img
{
    width: 320px;
}

.si-speaker::before
{
    content:"\A";
    border-style: solid;
    border-width: 36px 36px 36px 0;
    border-color: transparent #FC5420 transparent transparent;
    position: absolute;
    top: -24px;
    left: -5px;
    transform: rotate(45deg);
}

.si-placard {
    display: flex;
    flex-direction: column;
    padding: 22px 24px;
    position: absolute;
    background: #FFFFFF;
    /* min-height: 100px; */
    min-width: 320px;
    width: 320px;
    top: 300px;
    min-height: 160px;
    /* 
    Expect speaker-specific customizations:
        width, height, bottom, right    
    */
}

.si-placard::after
{
    content:"\A";
    border-style: solid;
    border-width: 36px 36px 36px 0;
    border-color: transparent #E5F6F8 transparent transparent;
    position: absolute;
    right: -8px;
    bottom: -23px;
    transform: rotate(225deg);
}

.si-placard-text-wrapper {
    flex: none;
    order: 0;
    /* align-self: center;     */
    align-self: flex-start;
}
.si-placard__name {
    position: static;
    /* H3 */
    font-family: RecifeDisplayRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    /* Gray 1 */
    color: #3B3B3B;    
    margin-top: 0px;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 6px;
    /* 
    Expect speaker-specific customizations:
        left, right, top, bottom
    */
}

.si-placard__title {
    position: static;
    /* Body 2 */
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* Gray 2 */
    color: #979797;    
    margin-left: 6px;
    /* 
    Expect speaker-specific customizations:
        left, right, top, bottom
    */
}

.si-type {
    position: absolute;
    top: 53px;
    right: -255px;
    /* Body 1 */
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;    
    /* Gray 1 */
    color: #3B3B3B;
    transform: rotate(90deg);
    transform-origin: top left;
    width: 267px;
}
@media screen and (max-width:990px) {    
    .si-container {
        /* height: calc(740px * 0.75 + 16px);                         
        width: calc(70% + 32px);        */
        
    }
    .si-wrapper {                
        transform: scale(0.7);
        transform-origin: top left;
    }
}

@media screen and (max-width:500px) {    
    .si-container {
        /* height: calc(740px * 0.65 + 16px);         
        width: calc(60% + 32px);  */
        
    }
    .si-wrapper {          
        transform: scale(0.6);
        transform-origin: top left;
    }
}

@media screen and (max-width:350px) {    
    .si-container {
        /* height: calc(740px * 0.45 + 16px);         
        width: calc(40% + 32px);  */
        
    }
    .si-wrapper {          
        transform: scale(0.4);
        transform-origin: top left;
    }
}

