.pf-container {
    height: 100%;
    width: 100%;
}
.pf-flex-outer-row {
    display: flex;
    flex-direction: row;
}

.pf-flex-rows {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.pf-vhhp-input {    
    display: inline-block;
    margin-left: 1rem;
    flex: 1 1 250px;
}
.pf-vhhp-input-full {
    display: inline-block;
    margin-left: 1rem;
    flex: 1 1 500px;
}
.pf-flex-col {
    flex: 3 1 600px;
}
.pf-picture-container {
    /* flex: 1 2 150px;
    margin-right: 2rem;
    text-align: center;    */
    
}
/* .pf-picture__avatar {    
    margin-bottom: 1rem;  
    display: inline-block;  
} */
.pf-picture__change {
    display: block; 
    cursor: pointer; 
}

.pf-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    width: 80vw;
    max-height: 95vh;

    background: #fff;
    overflow-y: auto;
    outline: none;
    z-index: 200;
}
.pf-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(32, 62, 104, 0.5);
    cursor: pointer;
    z-index: 200;
}
@media screen and (max-width: 960px) {
    .pf-flex-outer-row {
        flex-direction: column;
    }
    .pf-picture-container {
        margin-right: unset;
    }
    .pf-flex-rows {        
             
    }
    .pf-vhhp-input {
        display: block;
        margin-left: unset;
        flex: unset;
    }
}