@import '../../../../../common/common.scss';

.allrooms {
  &__title {
    padding: 3rem 0 2rem 0;
  }

  &__top-menu {
    justify-content: space-between;
  }

  &__room-dropdown {
    width: 30%;
  }

  &__top-menu {
    align-items: center;
  }

  &__left-menu {
    justify-content: space-between;
    align-items: flex-end;
    width: 660px;
  }

  &__search {
    border-bottom: 1px solid $gray-1;
    display: flex;
    padding-bottom: 0.5rem;

    input {
      margin-left: 0.5rem;
      width: 400px;
      border: none;
      outline: none;
      background-color: transparent;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1.3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

 /* the slides */
 .slick-slide {
   
   & > * {
    margin-bottom:1rem !important;
    margin-right: 2rem;
  }
}



//Rooms dropdown styles
.dropdown {
  position: relative;
  width: 183px;

  &__toggler {
    justify-content: space-between;
    align-items: center;
    border: 1px solid $gray-2;
    padding: 0.8rem 1.25rem;

    svg {
      transform: rotate(180deg);
    }
  }

  &__menu {
    position: absolute;
    width: 100%;
    height: 240px;
    padding: 1.25rem;
    z-index: 2;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  }

  &__placeholder {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $gray-2;
  }

  &__menu-item {
    margin-bottom: 0.8rem;

    .checkbox-container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: white;
      border: 1px solid $gray-2;
    }

    /* On mouse-over, add a grey background color */
    .checkbox-container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .checkbox-container input:checked ~ .checkmark {
      background-color: #66cfe7;
      border: none;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .checkbox-container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkbox-container .checkmark:after {
      left: 9px;
      top: 2px;
      width: 8px;
      height: 15px;
      border: solid #3b3b3b;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

@media screen and (min-width: 1920px) {
  .allrooms {
    &__cards {
      gap: 2rem;
    }
  }
}
