.fade-in {
    animation: fadeIn ease 1s;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.fade-out {
    animation: fadeOut ease 1100ms;
}

@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}
