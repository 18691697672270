.divider {
    padding-left: 20px;
    margin-right: 20px;
    height: 100%;
    display: inline-block;
}

.divider--gray {    
    border-right: 1px solid #3B3B3B;
}
.divider--white {
    border-right: 1px solid white;
}

.twin-logo-left {
    display: inline-block;
    height: 100%;
}

.twin-logo-right {
    display: inline-block;
    height: 100%;
}