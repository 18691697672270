@import '../../common/common.scss';

.sponsor-menu {
  // height: 450px;
  padding: 4rem 2rem 4rem 2rem;

  background-color: #e5f6f8;
  // background-color: $gold-6;

  &__header {
    font-size: 1.2rem;
    font-family: RecifeDisplayRegular;
    align-items: center;

    p {
      padding-right: 0.5rem;
    }
  }

  &__logo-wrapper {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
    gap: 3.25rem !important;
    place-items: center !important;
    padding-top: 4rem !important;
    //padding-bottom: 4rem;
  }
  &__logo {
    img {
      width: 100%;
    }
  }

  .liz-logo {
    img {
      // max-height: 50px;
    }
  }

  .frank {
    img {
    }
  }

  &__logos {
    img {
      max-width: 240px;
    }
  }
  //Alice carousel
  .alice-carousel {
    &__dots {
      margin: 50px 3px 5px !important;
      .__active {
        background-color: $gray-1 !important;
      }
    }

    &__dots-item {
      background-color: rgba($color: $gray-1, $alpha: 0.3) !important;
      width: 90px !important;
      height: 5px !important;
      border-radius: 0 !important;
    }

    &__next-btn {
      display: none !important;
    }

    &__prev-btn {
      display: none !important;
    }
  }
}

// .slick-dots li button::before {
//   content: '';
//   background-color: rgba($color: $gray-1, $alpha: 0.3) !important;
//   width: 90px !important;
//   height: 5px !important;
//   border-radius: 0 !important;
// }

// .slick-dots {
//   margin: 0px 10px !important;
// }

@media screen and (min-width: 580px) {
  .sponsor-menu {
    height: auto;
    padding: 4rem 2rem 4rem 2rem;

    &__logo-wrapper {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
}

@media screen and (min-width: 990px) {
  .sponsor-menu {
    padding: 4rem 2rem 4rem 2rem;

    &__carousel {
      width: 90%;
      max-width: 1100px;
      margin: 0 auto;
    }

    &__logo-wrapper {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }
}

@media screen and (min-width: 1660px) {
  .sponsor-menu {
    &__carousel {
      max-width: 1600px;
    }
  }
}
