// .auction-section {
//   &__container {
//     text-align: center;
//   }
//   &__header {
//   }
//   &__content {
//   }

//   &__title {
//     margin-bottom: 2rem;
//   }

//   &__text {
//     margin-bottom: 48px;

//     span {
//       font-weight: bold;
//     }
//   }
// }

// @media screen and (min-width: 990px) {
//   .auction-section {
//     &__header {
//       margin-bottom: 2rem;
//     }

//     &__text {
//       width: 700px;
//       margin-left: auto;
//       margin-right: auto;
//     }
//   }
// }
.orange-text
{
  color: #FC5420;
}
.silent-auction-container {
  width: 100%;
  min-height: 546px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #e5f6f8;
  background-image: url('../../img/silent_auction_background.png');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: contain;

}

.silent-auction-content {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-top: 6.25rem;
}

.silent-auction-content_header {
  margin-bottom: 2rem;
}

.silent-auction-content_title {
  margin-bottom: 2rem;
  color: #FC5420;
}

.silent-auction-content_body {
  font-family: MaisonNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 3rem;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 990px) {
  .silent-auction-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    text-align: left;
    }
  .auction-mobile-top-left-arrow
  {
      position: absolute;
      border-top: 50px solid #e5f6f8;
      border-left: 50px solid #e5f6f8;
      border-bottom: 50px solid transparent;
      border-right: 50px solid transparent;
  }
  .auction-mobile-bottom-right-arrow
  {
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom: 50px solid #e5f6f8;
    border-right: 50px solid #e5f6f8;
    border-top: 50px solid transparent;
    border-left: 50px solid transparent;
  }
  .silent-auction-content {
    text-align: left;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  .silent-auction-content_body {
    font-size: 1rem /*0.875rem 14px*/;
    line-height: 1.25rem /*1.125em 18px*/;
  }
  .silent-auction-content_title {
    margin-bottom: 2rem;
    /* h2 */
    font-size: 2.25rem;
    /*36px*/
    line-height: 2.75rem;
    /*44px*/
    letter-spacing: -0.0025rem;
    /*-0.4px*/
  }
  .silent-auction-content_header {
    /*becomes label--secondary*/
    font-size: 0.6875rem;
    line-height: 1rem;
    letter-spacing: 0.04375rem;
  }
}
