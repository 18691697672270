@import '../../common/common.scss';
.pfl-container {
    height: 100%;
    width: 100%;
    position: relative;    
}
.pfl-top-row {
    display: flex;
    flex-direction: row;    
    width: 100%;
}
.pfl-top-row__user {
    // background-color: $gold-6;
    width: 100%;    
    // padding-top: 3rem;
    display: flex;
    // background-image: url('./img/wbs_dashboard_header.png');
    // background-size: 100% auto;
    background-size: cover;
    // background-position: 0 auto;
    background-repeat: no-repeat;
    // background-image: url('./img/wbs_dashboard_header.png');
}
.pfl-user__avatar {          
    margin-left: 1.25rem;    
}
.pfl-user-info {    
    margin-left: 1.75rem;
    margin-bottom: 2.25rem;
}
.pfl-user-info__name {
    margin-bottom: 1rem;
}
.pfl-user-info__title {
    margin-bottom: 2.25rem;
}

.pfl-edit-profile__img {
    margin-right: .75rem;
    vertical-align: middle;
}

.pfl-top-row__gradient {  
    height:300px;
    width:595px;
    background-color: $gold-6;
    background: url(img/profile_gradient_texture.png);
    background-position: right top;
    background-repeat: no-repeat;
    /* position: absolute;    */

    /* background: url(Habitat_GradientTexture_4-07.png); */
}

.pfl-btm-row {
    width:100%;
    height: 100%;
    display: flex;
    background-image: url('./img/profile_background.png');
    background-repeat: no-repeat;
    // background-size: auto 100%;
    background-size: cover;
    background-position: right center;
}
.left-column-filler
{
    width: 100%;
    height: 80px;
    background-color: transparent;
    position: relative;
    overflow: hidden;
}
.left-column-white-brick
{
    width: calc(100% - 80px);
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
}
.left-column-filler::after
{
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 80px 80px 0;
    border-color: transparent #ffffff transparent transparent;
    right: 0px;
    top: 0;
    position: absolute;
    transform: rotate(180deg);
}

.pfl-btm-row__left {
    width: 50%;
    height: 100%;
    // padding-left: 2.5rem;
    // padding-right: 2.5rem;
    // padding-top: 2rem;
    // padding-bottom:1rem;
    // background-color: #ffffff;
}
.white-brick-container
{
    width: 100%;
    height: 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 2rem;
    padding-bottom:1rem;
    background-color: #ffffff;
}
.pfl-btm-row__right {
    width: 50%;        
    position:relative;
}
.pfl-btm-row__right--donate {    
    // background: linear-gradient(159.66deg, #66CFE7 -23.95%, rgba(255, 255, 255, 0) 88.99%), #F8F8F8; 
    // background-image: url('./img/wbs_dashboard_donate.png');
    // background-image: url('./img/profile_background.png');

    color: white;
    background-repeat: no-repeat;
    // background-size: auto 100%;
    background-size: cover;
    background-position: right center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.pfl-btm-row__right--sponsor {    
    background-color: $gray-5;    
}
.pfl-motif__img {
    position: absolute;
    right: 0;
    top: 100px;
    width: 60%;
    max-height: 90%;
}
.pfl-donate {
    width: 100%;
    padding: 3rem;
    background-color: #D7F7FC;
    color: #3B3B3B;
}
.pfl-donate__text {
    /* h2 */
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 2.25rem; /*36px*/
    line-height: 2.75rem; /*44px*/    
    letter-spacing: -.0025rem; /*-0.4px*/
    margin-bottom: 2rem;
    width: 60%;
}
.pfl-agenda-title {
    /* h3 */
    font-family: RecifeDisplayRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem /*24px*/;
    line-height: 1.75rem /*28px*/;    
    letter-spacing: -0.00625rem /*-0.1px*/;
    margin-bottom: 2rem;
}

.invitation-code-input {
    width: 50%;
    padding: .875rem 1rem;
    font-family: MaisonNeue;
    line-height: 1.25rem;
    font-size: 1rem;
    border: 1px solid #E3E3E3;
    outline: none;
    box-sizing: border-box;
    margin-bottom: 1.25rem;
}

@media screen and (max-width: 960px) {
    .pfl-top-row__user {
        padding-top: 2rem;
    }
    .pfl-top-row__gradient {
        display: none;
    }
    .pfl-btm-row {
        flex-direction: column;
    }
    .pfl-btm-row__left {
        width: 100%;
        margin: 0;
        margin-top: 120px;
        // padding: 2rem;
    }
    .pfl-btm-row__right {
        width: 100%;
    }
    .pfl-motif__img {
        width: unset;
        height: 80%;
        top: 20px;
    }
    .pfl-donate {
        margin: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .pfl-motif__img {
        display: none;
    }
    .pfl-donate__text {
        width: unset;
    }
}

