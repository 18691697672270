@import '../../common/common.scss';

.faq {
  &__breadcrumbs {
  }

  &__container {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  &__header {
    margin-bottom: 2rem;
  }

  &__content {
    background-color: #f4f4f4;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    & > *:not(:last-child) {
      border-bottom: 1px solid $gray-1;
      padding: 1.5rem 0;
    }

    & > :last-child {
      padding-top: 1.5rem;
    }
  }
}

.faqitem {
  display: flex;

  &__arrow {
    margin-right: 20px;
  }

  .transition {
    transition: all 400ms ease-in-out;
  }

  .close {
    svg {
      transform: rotate(180deg);
    }
  }

  .open {
    svg {
      transform: rotate(0deg);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    .question {
      margin-bottom: 1rem;
    }

    .answer {
    }
  }
}

@media screen and (min-width: 990px) {
  .faq {
    &__content {
      padding-top: 2.25rem;
      padding-bottom: 2.25rem;
    }

    &__breadcrumbs {
      margin-top: 2rem;
      padding-left: 5.25rem;
    }
  }
}

@media screen and (min-width: 1440px) {
  .faq {
    &__content {
      padding-top: 3.25rem;
      padding-bottom: 3.25rem;
    }

    &__breadcrumbs {
      margin-top: 4rem;
    }
  }
}
