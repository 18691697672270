/* desktop first styles */
.container {
    height: 100%;
    width: 100%;
}

.sections-container {

}

.section {
    position: relative;
}

.--hide {
    display: none;
}

.home {
    background-color: #F59166; /* #2B538B;*/
}

.about {
    background-color: #F2C4A8;
}
.speakers {
    background-color: var(--blue-6);
}
.sponsors,
.oursponsors {
    background-color: var(--gold-6);
}
.agenda {
   background-color: var(--red-6);
}
.support {
    background-color: var(--green-6);
}
.register {
    background-color: var(--green-1);
}
.silentauction {
    background-color: var(--red-6);
}

.pre-event-nav-target {
    position: absolute;
    visibility: hidden;
}

@media screen and (max-width: 1440px) {
    /* mobile styles */
    .menu-margin-big {    
        margin-top: 145px;
    }
}