/* Mobile First Styles */
.habitat-nyc {
  background-color: #C2EFF2;
  padding-top: 12px;
  padding-bottom: 8px;
  text-align: center;
}
.hide {
  display: none !important;
}

.top-menu {
  background-color: #ffffff;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.08);
}

.top-menu.dark {
  background: #030303;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.08);
  color: #ffffff;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
}

.top-menu-left {
}

.top-menu-right {
  position: absolute;
  top: 0;
  right: 0;
}

.text-menu-item {
  display: none;
  padding: 1rem;
}

.top-menu.dark .text-menu-item {
  color: #ffffff !important;
}

.menu-icon {
  display: inline-block;
  padding: 25px;
  border: none;
  background-color: white;
}
.menu-icon.dark {
  background-color: #222222;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.08);
}

.menu-icon:focus {
  outline: none;
}
.menu-icon:hover,
.menu-icon:active {
  background-color: #66cfe7;
}

.top-menu.responsive {
}

.top-menu.responsive .text-menu-item {
  display: block;
  text-align: left;
}

.menu-icon-image {
  content: url('img/menu-icon.png');
  width: 16px;
  height: 12px;
}
.menu-icon-image.dark {
  content: url('img/menu-icon-dark-theme.png');
  width: 16px;
  height: 12px;
}

.menu-icon-image:before {
  display: inline-block;
  text-rendering: auto;
}
.a-auth-menu-avatar {
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: baseline;
  cursor: pointer;
}
.auth-menu-avatar {
  display: inline-block;
}
.auth-menu-avatar__span {
  padding-right: 0.5rem;
}

.logo {
  height: 60px;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: 20px;
  vertical-align: middle;
}
.topmenu-donate-button {
  font-size: 0.875rem !important /*14px*/;
  line-height: 1.5rem !important/*24px*/;
  letter-spacing: -0.00625rem !important/*0.1px*/;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  background-color: #FC5420 !important;
}
.donate-button-container {
  display: inline-block;
  padding-right: 10px;
  margin-left: 1.5rem;
}

@media screen and (max-width: 960px) {
  .desktop-only {
    display: none !important;
  }

  .top-menu.responsive .a-auth-menu-avatar {
    display: flex;
    align-items: center;
    height: 3.5rem;
  }
}

@media screen and (min-width: 960px) {
  /* desktop */
  .mobile-only {
    display: none !important;
  }
  .desktop-only {
    display: inline-block;
  }
  .donate-button-container {
    padding-right: unset;
  }

  .habitat-nyc {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .habitat-nyc > div {
    display: inline;
    margin: 6px;
  }
  .habitat-nyc img {
    padding: 0;
    vertical-align: middle;
  }
  .habitat-nyc div.body3 {
    line-height: unset;
    vertical-align: middle;
  }
  .text-menu-item {
    color: #3b3b3b !important;
  }
  .text-menu-item:hover,
  .text-menu-item:active {
    color: #2b538b !important;
  }
  .menu-icon {
    display: none !important;
  }
  .top-menu.responsive {
  }
  .top-menu.responsive .top-menu-right {
  }
  .top-menu.responsive .text-menu-item {
    display: inline-block;
    text-align: unset;
  }
  .text-menu-item {
    display: inline-block;
  }
  html[data-scroll='0'] .top-menu-left a:nth-child(2) {
    margin-left: 150px;
  }
    html[data-scroll='0'] img.logo {    
      width: 95px;
      height: unset;
      position: absolute;
      margin-bottom: 0;
      margin-top: 12px;
      left: 0;    
  }
  html:not([data-scroll='0']) img.logo {
    height: 42px;
  }

  html:not([data-scroll='0']) .top-menu-left a:nth-child(2) {
    margin-left: 42px;
  }
}
