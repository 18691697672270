/* Styles for the first of three slides for home section */

.home-container {
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: row;    
}
.bl-box {
    /*bottom/left box depending on screen width */
    /* width: 60%;
    height: 100%;
    flex: 1 1 auto;
    background-position: left center;
    background-size: auto 100%;
    background-repeat: no-repeat; */
    position: absolute;
    width: 954px;
    height: 482px;
    left: 0px;
    top: 0px;

    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.06);
    border-bottom-right-radius: 750px;
    z-index: 1;
}

.bl-box_content {
    margin-left: 9.75rem /*156px*/;
    margin-right: 6.75rem;
    margin-top: 6.25rem /*100px*/;
    margin-bottom: 6.25rem;
}

.bl-box_title {
    margin-bottom: 1.25rem;
}

.bl-box_date {
    margin-bottom: 3rem;
}
.bl-box_body {
    margin-bottom: 3rem;
}

.tr-box {
    /* top/right box */
    /* width: 41%;
    height: 100%;     
    flex: 1 1 auto;  */
    width: 40%;
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1EA8D0;
    /* padding: 20px; */
    /* background-image: url('./img/wbs_header-bg.png');
    background-position: left center;
    background-size: auto 100%;
    background-repeat: no-repeat; */
}

.tr-box_img-container {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.tr-box_img-container_img {    
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: right bottom;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.tr-box_mp4 {
    /* height: 100%; */
    width: 100%;    
}

@media screen and (max-width:990px) {
    /* mobile styles */
    .home-container {        
        flex-direction: column-reverse;
        height: unset;
        min-height: 450px;
    }
    .bl-box {
        width: 100%;
        /* min-height: 425px; */
        /* height: 40vh; */
        position: absolute;
        min-width: 375px;
        max-height: 280px;
        left: 0;
        top: 0;

        background: #FFFFFF;
        border-bottom-right-radius: 150px;
        /* transform: matrix(-1, 0, 0, 1, 0, 0); */
    }
    .tr-box {
        width: 100%;
        /* min-height: 350px; */
        min-height: 450px;
        height: 40vh;
        display: flex;
        flex-direction: column;
        object-fit: cover;
        object-position: bottom right;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .tr-box_mp4 {
        width: 100%;
        min-height: 350px;
        height: 40vh;
        display: flex;
        flex-direction: column;
        object-fit: cover;
        object-position: bottom right;  
    }
    
    .bl-box_content {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-top: 0.875rem;
        margin-bottom: 2rem;
    }
    .bl-box_title,
    .bl-box_date {
        /* h1 */
        font-size: 3rem /* 48px*/;
        line-height: 3.25rem /*52px*/;
        letter-spacing: -0.025rem /*-0.4px*/;
    }
    .bl-box_date
    {
        margin-bottom: 0.5em;
    }
    .vhhp-button {
        padding-right: 2rem;
        padding-left: 2rem;
    }
}

