/*
-wrapper {
    margin-top: px;
}
-placard {
    width: px;
    height: px;
    top: px;
    left: px;
}
-name {
    left: %;
    right: %;
    top: %;
    bottom: %;
}
-title {
    left: %;
    right: %;
    top: %;
    bottom: %;
}
*/
.sp0-wrapper {
    top: 19.6%; 
    /* margin-top: 161px; */
    /* calc((161 / 820) *100%); */
}
.sp0-placard {
    width: 262px;
    /* height: 106px; */
    top: 369px;
    left: 188px;
}
.sp0-name {
    left: 0%;
    right: 4.21%;
    top: 0%;
    bottom: 54.84%;
}
.sp0-title {
    left: 0%;
    right: 0%;
    top: 54.84%;
    bottom: 0%;
}

.sp1-wrapper {
    /* margin-top: 20px; */
    top: 2.4%
}
.sp1-placard {
    width: 262px;
    /* height: 106px; */
    /* bottom: 53px; */
    top: 579px;
    left: 50px;
}
.sp1-name {
    left: 2.1%;
    right: 2.1%;
    top: 0%;
    bottom: 54.84%;
}
.sp1-title {
    left: 0%;
    right: 0%;
    top: 54.84%;
    bottom: 0%;
}
.sp2-wrapper {
    /* margin-top: 143px; */
    top: 17.4%;
}
.sp2-placard {
    width: 262px;
    /* height: 106px; */
    bottom: 21px;
    right: 8px;
}
.sp2-name {
    left: 0%;
    right: 4.21%;
    top: 0%;
    bottom: 54.84%;
}
.sp2-title {
    left: 0%;
    right: 0%;
    top: 54.84%;
    bottom: 0%;
}
.sp3-wrapper {
    /* margin-top: 16px; */
    top: 2%;
}
.sp3-placard {
    width: 262px;
    /* height: 124px; */
    bottom: -9px;
    right: 52px;
}
.sp3-name {
    left: 0%;
    right: 4.21%;
    top: 0%;
    bottom: 65%;
}
.sp3-title {
    left: 0%;
    right: 0%;
    top: 42.5%;
    bottom: 0%;
}
.sp4-wrapper {
    /* margin-top: 125px; */
    top: 15.2%
}
.sp4-placard {
    width: 262px;
    /* height: 124px; */
    left: 51px;
    top: 376px;
}
.sp4-name {
    left: 0%;
    right: 4.21%;
    top: 0%;
    bottom: 65%;
}
.sp4-title {
    left: 0%;
    right: 0%;
    top: 42.5%;
    bottom: 0%;
}
.sp5-wrapper {
    /* margin-top: 68px; */
    top: 8%;
}
.sp5-placard {
    width: 268px;
    /* height: 134px; */
    left: 124px;
    top: 366px;    
}
.sp5-name {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 37.78%;
}
.sp5-title {
    left: 0%;
    right: 0%;
    top: 68.89%;
    bottom: 0%;
}
.sp6-wrapper {
    /* margin-top: 16px; */
    top: 2%;
}
.sp6-placard {
    width: 262px;
    /* height: 124px; */
    left: 50px;
    top: 566px;
}
.sp6-name {
    left: 0%;
    right: 4.21%;
    top: 0%;
    bottom: 65%;
}
.sp6-title {
    left: 0%;
    right: 0%;
    top: 42.5%;
    bottom: 0%;
}
.sp7-wrapper {
    /* margin-top: 36px; */
    top: 4.3%;
}
.sp7-placard {
    width: 325px;
    height: 106px;
    left: 50px;
    top: 439px;
}
.sp7-name {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 54.84%;
}
.sp7-title {
    left: 0%;
    right: 0%;
    top: 54.84%;
    bottom: 0%;
}
.sp8-wrapper {
    top: 0;
}
.sp8-placard {
    width: 325px;
    height: 154px;
    left: 50px;
    top: 554px;    
}
.sp8-name {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 49.09%;
}
.sp8-title {
    left: 0%;
    right: 0%;
    top: 56.36%;
    bottom: 0%;
}
.sp9-wrapper {
    /* margin-top: 137px; */
    top: 16.7%;
}
.sp9-placard {
    width: 260px;
    height: 130px;
    left: 50px;
    top: 388px;
}
.sp9-name {
    left: 9.23%;
    right: 9.23%;
    top: 16.92%;
    bottom: 61.54%;
}
.sp9-title {
    left: 9.23%;
    right: 9.23%;
    top: 46.15%;
    bottom: 16.92%;
}