.info-box--fade-in {
    animation: infoBoxFadeIn ease 1s;
}

@keyframes infoBoxFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.info-box--fade-out {
    animation: infoBoxFadeOut ease 1100ms;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.info-box {
    margin: 0;
    padding: 0;
    position: fixed;
    height: 100%;
    overflow: hidden;
    min-height: 900px;
}

.info-box-0 {
    background-color: #FFF1B5;
}

.info-box-1 {
    background-color: #66CFE7;
}

.info-box-2 {
    background-color: #3668AE;
}

.info-box__content {
    margin-left: 2.5rem;
    margin-top: 2.4rem;
    margin-right: 8.4375rem;
    z-index: 10;    
}

.info-box__title {
    margin-top: 5.25rem;
    /*h1*/
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 3rem;
    line-height: 3.25rem;
    letter-spacing: -0.025rem;

    /* margin to fix possible image overlap on very narrow screens */
    margin-right: 5rem; 
}

.info-box__short {
    margin-top: 1.25rem;
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    opacity: 0.8;
}

.info-box__date {
    margin-top: 1.875rem;
    /*label--primary*/
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0025rem;
    text-transform: uppercase;
}

.info-box__img-upper {
    position: absolute;
    z-index: -1;  
}

.info-box__img-lower {
    position: absolute;    
    z-index: -1;  
}

.info-box__img-upper-0 {
    width: 667px;
    top: -180px;
    right: -552px;
}

.info-box__img-lower-0 {
    width: 707px;
    bottom: -263px;
    right: -132px;
}

.info-box__img-upper-1 {
    width: 652px;
    top: -246px;
    right: -671px;
    transform: rotate(30deg);
    transform-origin: top left;
    z-index: -1;
}

.info-box__img-lower-1 {
    width: 809px;
    bottom: -138px;
    right: -389px;
    transform: rotate(15deg);
    transform-origin: top left;
    z-index: -1;
}

.info-box__img-upper-2 {
    width: 602px;    
    top: -200px;
    right: -1070px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    transform-origin: top left;
}

.info-box__img-lower-2 {
    width: 606px;
    bottom: -258px;
    right: -682px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    transform-origin: top left;
}

@media screen and (min-width: 1681px) {
    .info-box {
        width: 45%;
    }
    .info-box__content {
        width: 676px;
        position: absolute;
        right: 0px;
    }
}

@media screen and (max-width: 1680px) {
    .info-box {
        width: 615px;
    }
}

@media screen and (max-width: 1440px) {
    .info-box {
        width: 415px;
    }
    .info-box__img-upper-0 {
        right: -582px;
    }
    .info-box__img-lower-0 {
        right: -332px;
    }
    .info-box__img-upper-1 {
        right: -682px;
    }
    .info-box__img-lower-1 {
        right: -652px;
    }
    .info-box__img-upper-2 {
        z-index: -2;
        right: -1120px;
    }
    .info-box__img-lower-2 {
        right: -882px;
    }
}

@media screen and (max-width: 960px) {
    /*styles that apply to all single column widths*/
    .info-box {
        height: 225px;
    }
    .info-box__img-upper {        
        z-index: auto;  
    }
    .info-box-0, .info-box-1, .info-box-2 {
        width: 100%;
        position: relative;
        min-height: unset;
    }
    .info-box__img-upper-0 {
        width: 260px;
        right: -58px;
        top: 75px;
    }
    .info-box__img-upper-1 {
        width: 216px;
        right: -133px;
        top: 10px;
        transform: rotate(15deg);        
    }
    .info-box__img-upper-2 {
        width: 227px;
        top: 28px;
        right: -340px;        
    }
    .info-box__img-lower {
        display: none;
    }
    .info-box__content {
        margin-left: 2.25rem;
        margin-top: 2.25rem;
        margin-right: 6rem;
    }
    .info-box__title {
        margin-top: 1.875rem;
        /*h3*/
        font-family: RecifeDisplayBook;
        font-style: normal;
        font-weight: normal;
        font-size: 1.5rem/*24px*/
        ;
        line-height: 1.75rem/*28px*/
        ;
        display: flex;
        align-items: center;
        letter-spacing: -0.00625rem/*-0.1px*/
        ;
    }
    .info-box__short {
        display: none;
    }
    .info-box__date {
        margin-top: 1rem;
        /*label--secondary*/
        font-family: MaisonNeue;
        font-style: normal;
        font-weight: 500;
        font-size: 0.6875rem;
        line-height: 1rem;
        letter-spacing: 0.04375rem;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 765px) {
    .info-box {
        height: 180px;
    }
    .info-box__content {
        margin-top: 0.75rem;
        margin-left: 1.25rem;
    }
    .info-box__title {
        margin-top: 1.5rem;
        /*h4*/
        font-size: 1.125rem;
        line-height: 1.25rem;
        letter-spacing: -0.00625rem;
    }
    .info-box__date {
        margin-top: 1rem;
    }
    .info-box__img-upper-0 {
        width: 260px;
        right: -58px;
        top: 30px;
    }
    .info-box__img-upper-1 {
        width: 216px;
        right: -133px;
        top: -11px;
        transform: rotate(15deg);
        z-index: auto;
    }
    .info-box__img-upper-2 {
        width: 227px;
        top: 28px;
        right: -340px;
    }
}

@media screen and (max-width: 660px) {
    /*the "375" styles -- start early at 660*/
    .info-box__img-upper-0 {
        width: 260px;
        right: -158px;
        top: 30px;
    }
}