/* Mobile First */
.wizard-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;    
    height: 100vh;
}

.wizard-body {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%; 
    overflow: hidden;   
}

.wizard-sidebar {
}

.wizard-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;    
}

.wizard-content-pane {
    flex: 1 1 auto;
    padding: 2rem;    
    overflow: auto;
    margin-bottom: 52px;
    
}

.wizard-nav {
    border-top: 1px solid #E3E3E3;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 52px;
    background-color: white;
}
.wizard-footer {
}

.wizard-nav__back-button {    
    margin-top: 1rem;    
    display: inline-block;
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding-left: 1rem;
    /* identical to box height, or 171% */
    
    letter-spacing: 0.1px;
    
    /* Gray 1 */
    
    color: #3B3B3B;
    opacity: 0.8;
}

.wizard-nav__skip-button {
    margin-top: 1rem;    
    display: inline-block;
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.wizard-nav__next-button {
    width: 40% !important;
    float: right;
}

@media screen and (min-width: 960px) {
    .wizard-container {
        width: 100%;
        max-width: 1440px;
    }
    .wizard-body {
        flex-direction: row;
    }
    .wizard-sidebar {
        flex: 0 0 365px;
    }

    .wizard-nav {
        /* margin-bottom: 2rem; */
        /* margin-left: 6rem;
        margin-right: 6rem; */
        width: calc(100vw - 365px);
        max-width: calc(1440px - 365px);
        left: 365px;
    }    
    .next-button {
        width: unset !important;
        margin-top:1em;
    }

    .wizard-content {
        /* overflow: auto; */
        
        
    }
    .wizard-content-pane {
        /* Design Margins: 
        margin: 6rem 9.6875rem 0 8.4375rem;
        */
        margin: 0 0 52px 0;
        padding: 6rem 6rem 1rem 6rem; 

    }

    .wizard-content-pane, .wizard-nav {
        

        /* max-width: 1076px; */
    }
}
