.si-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 1px;
    height: fit-content;
    width: 80vw;
    max-width: 1200px;
    max-height: 900px;
    /* max-height: 95vh; */
    background: #fff;
    outline: none;
    cursor: unset;
    overflow: auto;
}

@media screen and (max-width: 600px) {
    .si-modal-body {
        margin: 0 !important;
        padding: 1rem;
    }
    .si-modal {
        width: 95vw;
        max-height: calc(100vh - 100px);
    }
}

.si-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(32, 62, 104, 0.5);
    cursor: pointer;
    z-index: 200;
    cursor: unset;
}

.si-modal-content {
    width: 100%;
    height: auto;
    position: relative;
}

.si-modal-banner {
    height: 160px;
    background-color: #66CFE7;
    background-image: url('img/wbs_speaker-bio-header.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
}

.si-modal-banner__img {
    position: relative;
    right: 0;
    top: 0;
    vertical-align: middle;
}

.si-modal-body {
    margin-top: 2rem;
    margin-left: 12%;
    margin-right: 12%;
    margin-bottom: 4rem;
}

.si-speaker-thumb {
    display: inline-block;
}

.si-speaker-thumb__img {
    height: 120px;
    display: inline-block;
}

.si-speaker-head {
    display: inline-block;
    vertical-align: top;
    margin-left: 1rem;
}

.si-speaker-name {
    margin-top: 1.25rem;
    margin-bottom: .625rem;
}

.si-speaker-title {
    margin-bottom: 2.75rem;
}

.si-speaker-bio {
    /* overflow: auto; 
   padding: .5rem;   
   max-height: 400px; */
}

.si-modal__close-button {
    position: absolute;
    right: 0;
    top: -35px;
    /* background: none; */
    /* outline: none;
    border: none;
     */
    align-self: flex-end;
    background-color: transparent;
    border-radius: 50%;
    border: 0;
    color: #000000;
    font-size: 30px;
    /* font-weight: bold; */
    height: 45px;
    text-align: center;
    width: 45px;
    z-index: 999999;
    cursor: pointer;
}