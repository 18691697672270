/* Desktop-first Styles */
.about-container {
  display: flex;
  flex-direction: row;
  // height: 800px;
  height: fit-content;
  width: 100%;
  background-color: #ffffff;
  /*background-color: #addadf;*/
}

.tl-box {
  /* top/left box depending on screen width */
  width: 49%;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.tl-box_nav-target {
  /* height: 0px;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; */
  /*top: -12px;*/
}
.tl-box_content {
  margin-left: 9.75rem /*156px*/;
  margin-right: 6.25rem;
  margin-top: 6.25rem /*100px*/;
}
.tl-box_header {
  margin-bottom: 1.25rem;
}

.tl-box_title {
  margin-bottom: 0.25rem;
}
.tl-box_img-container {
  height: 100%;
  width: 100%;
}
.tl-box_img-container_img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: left;
}
/* .tl-box_title {
    margin-bottom: 1rem;
} */

.br-box {
  /* bottom/right box */
  width: 51%;
  height: 100%;
}
.br-box_content {
  margin-top: calc(6.25rem + 0.875rem + 1.25rem);
  margin-left: 4.5rem /*72px*/;
  margin-right: 4.5rem;
  max-width: 600px;

  p {
    margin-bottom: 2rem;
  }
}
.tl-box_img-container_img.about-img-large
{
  display: block;
}
.tl-box_img-container_img.about-img-shrink
{
  display: none;
}
.shrink-display-section
{
  display: none;
}
@media screen and (max-width: 990px) {
  .shrink-display-section
  {
    display: block;
  }
  .tl-box_img-container_img.about-img-large
  {
    display: none;
  }
  .tl-box_img-container_img.about-img-shrink
  {
    display: block;
  }

  .about-container {
    flex-direction: column;
    height: unset;
  }
  .tl-box {
    width: 100%;
    /* height: 40vh; */
  }
  .tl-box_content {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .br-box {
    width: 100%;
    /* height: 30vh; */
  }
  .br-box_content {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    width: auto;
  }
  .tl-box_title {
    font-size: 2.25rem;
    line-height: 2.75rem;
    letter-spacing: -0.0025rem;
  }
  .tl-box_header {
    /*becomes label--secondary*/
    font-size: 0.6875rem;
    line-height: 1rem;
    letter-spacing: 0.04375rem;
  }
}
