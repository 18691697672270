@import '../../../common/common.scss';

.orange-text
{
  color:#FC5420;
}

.support-container {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  position: relative;
}
.support-up-left-arrow
{
  position: absolute;
  top: 0;
  left: 0;
  border-top: 50px solid #e5f6f8;
  border-left: 50px solid #e5f6f8;
  border-right: 50px solid transparent;
  border-bottom: 50px solid transparent;
}

.support-tl-box {
  width: 45%;
  height: 100%;
  flex: 1 1 auto;
}
.support-tl-box-content {
  margin-left: 9.75rem;
  margin-right: 6.25rem;
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}
.support-tl-box_header {
  margin-bottom: 1.25rem;
}
.support-tl-box_title {
  margin-bottom: 1rem;
}
.support-tl-box_body {
  margin-bottom: 3rem;
}
.support-br-box {
  width: 55%;
  height: 100%;
  flex: 1 1 auto;
}
.support-br-box_img-container {
  height: 100%;
  width: 100%;
}
.support-br-box_img-container_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}
@media screen and (max-width: 990px) {
  .support-up-left-arrow
  {
    display: none;
  }
  .support-container {
    flex-direction: column-reverse;
    height: unset;
  }
  .support-tl-box {
    width: 100%;
  }
  .support-tl-box-content {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  .support-tl-box_header {
    /*becomes label--secondary*/
    font-size: 0.6875rem;
    line-height: 1rem;
    letter-spacing: 0.04375rem;
  }
  .support-tl-box_title {
    margin-bottom: 2rem;
    /* h2 */
    font-size: 2.25rem; /*36px*/
    line-height: 2.75rem; /*44px*/
    letter-spacing: -0.0025rem; /*-0.4px*/
  }
  .support-tl-box_body {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .support-br-box {
    width: 100%;
  }
  .vhhp-button {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
