.tc-overflow {
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 1em;
    /* overflow: auto; */
    border: 1px solid #E3E3E3;
    flex-grow: 1;
    /* height: 55vh; */
}

.tc-footer {
}   

.tc-title {
    margin-top: 0;
    font-size: 1.2em;
    vertical-align: middle;;
}

.tc-section-header {
    text-transform: uppercase;
}

.tc-footer label {
    font-size: 14px;
    line-height: 18px;
}

/* temp */
.tc-footer__checkbox {
    /* width: 24px;
    height: 24px; */
    margin-right: .5rem;
}
