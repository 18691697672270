.sponsors-container {
    width: 100%;
    min-height: 553px;
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #E5F6F8;
}

.sponsors-content {
    margin-left: 9.75rem;
    margin-right: 6.25rem;
    margin-top: 6.25rem; 
    z-index: 1;
    
}
.sponsors-content_header {
    margin-bottom: 1.25rem;
    color: #ffffff;

}
.sponsors-content_title {
    margin-bottom: 1rem;
    color: #ffffff;
}
.sponsors-content_body {
    max-width: 554px;
    margin-bottom: 3rem;
    line-height: 1.5rem;
    color: #ffffff;
}

.sponsors-img-container {    
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url('./img/Sponspor_BG_Web.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    /* background-size: 100% auto; */
    background-size: cover;
}
.sponsors-img-container_img {
    /* width: 100%; */
    height: 100%;
    object-fit: contain;
    /* height: 100%;
    float: right;
    object-fit: contain; */
}
.sponsor-btn-container
{
    display: block;
}
.sponsor-btn-container button
{
    border-radius: 3px;
}
.sponsor-shrink-btn-container
{
    display: none;
}
.sponsor-bottom-img-container
{
    width: 100%;
    display: none;
}

.sponsor-url-text
{
    color: #ffffff;
    font-weight: bolder;
    font-size: 1.05em;
}

.sponsor-url-text:hover
{
    color: #E5F6F8;
    font-weight: bolder;
    font-size: 1.05em;
    text-decoration: underline;
}
@media screen and (max-width:990px) {
    .sponsor-url-text
    {
        color: #000000;
        font-weight: bolder;
        font-size: 1.05em;
    }
    .sponsor-btn-container
    {
        display: none;
    }
    .sponsor-shrink-background
    {
        background-color: #1EA8D0;
        border-bottom-right-radius: 300px;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 3rem;
        padding-bottom: 2rem;
    }
    .sponsor-shrink-btn-container
    {
        display: block;
    }
    .sponsor-shrink-btn-container
    {
        padding-left: 1.25rem;
    }
    .sponsor-shrink-btn-container button
    {
        border-radius: 3px;
    }
    .sponsor-bottom-img-container
    {
        /* width: 100%; */
        margin-top: 20px;
        display: block;
    }
    .sponsor-bottom-img
    {
        width: 100%;
    }
    .sponsors-container {
        display: flex;
        flex-direction: column;
        height: unset;
    }
    .sponsors-img-container {
        position: unset;        
    }
    .sponsors-img-container_img {
        height: unset;
        width: 100%;
        max-height: 450px;
    }
    .sponsors-content {
        margin-left: 0px;
        /* margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-top: 3rem;
        margin-bottom: 2rem; */
    }
    .sponsors-content_body {
        font-size: 1rem /*0.875rem 14px*/;
        line-height: 1.25rem /*1.125em 18px*/;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 1.25rem;
        color: #000000;
    }
    .sponsors-content_title {
        margin-bottom: 2rem;
        /* h2 */
        font-size: 2.25rem; /*36px*/
        line-height: 2.75rem; /*44px*/    
        letter-spacing: -.0025rem; /*-0.4px*/
    }
    .sponsors-content_header {
        /*becomes label--secondary*/
        font-size: 0.6875rem;
        line-height: 1rem;
        letter-spacing: 0.04375rem;
    }
    .vhhp-button {
        padding-right: 2rem;
        padding-left: 2rem;
    }
}