@import '../../../common/common.scss';

.speakers-container {
  width: 100%;
  position: relative;
  padding-bottom: 3rem;
  background-color: $blue-6;

}
.speakers-header {
  width: 100%;
  padding-left: 9.75rem;
  padding-top: 6.25rem;
  padding-bottom: 1.25rem;
}

.speakers-carousel-container {
  width: 100%;
  position: relative; 
}

.speakers-slick-left-padding {
  padding-left: 1.25rem;
}



@media screen and (max-width: 990px) {  
  .speakers-header {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 3rem;
    padding-bottom: 1.25rem;
  }
  .label--primary {
    /*becomes label--secondary*/
    font-size: 0.6875rem;
    line-height: 1rem;
    letter-spacing: 0.04375rem;
  }
  .slick-slide > * {
    margin-right: 1rem !important;
  }
  .speakers-carousel-container
  {
    .slick-slider
    {
      .slick-dots
      {
        bottom: -50px !important;
      }
    }

  }

}

@media screen and (max-width: 500px)
{
  .speakers-carousel-container
  {
    .slick-slider
    {
      .slick-dots
      {
        bottom: -60px !important;
      }
    }

  }
}