@import '../../../../common/common.scss';

.rooms-service {
  &__join-tab {
    background-color: #c4d600;
    width: 100%;
    text-align: center;
    padding: 1rem 0;
  }

  &__container {
    background-color: $gray-5;
    padding: 4rem 2rem 2rem 2rem;
  }

  &__header {
    border-bottom: 1px solid $gray-1;
    padding-bottom: 3rem;
  }

  &__title {
    justify-content: space-between;
    align-items: flex-start;

    h3 {
      font-size: 32px;
      font-weight: normal;
      font-family: RecifeDisplayBook;
      line-height: 1.75rem /*28px*/;
      margin-bottom: 2rem;
    }
  }

  
  &__close-rooms {
    svg {
      margin-left: 1rem;
    }
  }

  &__info {
    width: 60%;
    text-align: justify;
  }

  &__recom-title {
    padding: 2rem 0;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1.3rem;
  }

  &__all-rooms {
    //padding: 2rem 0;
  }
}

@media screen and (min-width: 1920px) {
  .rooms-service {
    &__cards {
      gap: 2rem;
    }

    &__container {
      padding: 4rem 4rem 2rem 4rem;
    }
  }
}
