@import '../../common/common.scss';

.chatbox {
    overflow-y: scroll;
    flex-grow: 1;
    height: 100%;

    .users-chat-wrapper, .user-chat {
        display: flex;
        flex-grow: 1;
        height: 100%;
        flex-direction: column;
    }

    .new-message {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        

        .thumbnail {
            padding: 10px;
            img {
                height: 36px;
                width: 36px;
                border-radius: 100%;
            }
        }

        .content {
            flex-grow: 1;
            padding: 10px 40px 10px 10px;
            width: 50%;
            word-wrap: break-word;

            .name {
                opacity: 0.5;
            }
        }
    
        .time {
            //opacity: 0.25;
            white-space: nowrap;
            padding-top: 10px;
        }

        .new-msg{
            margin-top: 5px;
            margin-left: 15px;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background-color: $red-3;
            text-align: center;
            line-height: 24px;
        }

    }

    .chat-filler {
        justify-content: center;
        display: flex;
        align-items: center;
        height: 100%;
    }

}

.new-chat-msgs {
    height: 100%;
    overflow: hidden;
    flex-grow: 1;
}


.search-chat{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    &__input{
        padding: 0.3rem 1rem;
        background-color: $gray-2;
        color: white;
        border: none;
        outline: none;
        width: 100%;
        height: 42px;
        margin-right: 1rem;
        
        &::placeholder{
            color: white;
        }
    }
}

.direct-msg-title{
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
}

.chat-filler {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
}

.chat-ui{
    &__header{
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
      

        .user-name{
            margin: 0 auto;
            justify-self: center;
        }
    }

    &__back {
        display: flex;
        align-items: center;
        img{
            margin-right: 0.3rem;
        }
    }

    &__messages{
        display: flex;
        flex-direction: column;
        &__message{
            padding: 0.7rem 1rem;
            max-width: 400px !important;
            border: 1px solid #979797;
            border-radius: 4px;
            align-self: flex-start;
            margin-bottom: 0.5rem;
            word-break: break-word;
            &.self {
                align-self: flex-end;
                border: none;
                background-color: $gray-2;
            }
        }
    }
}