.form-box {}

.form-box-content {
    margin-top: 6.25rem;
    margin-right: 9.1rem;
    margin-left: 9.1rem;
}

.form-box__title {
    margin-bottom: 1.875rem;
    /*h1*/
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 3rem;
    line-height: 3.25rem;
    letter-spacing: -0.025rem;
}

.form-box__text {
    /* h4 */
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.25rem;
    letter-spacing: -0.00625rem;
}
.a-large {
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.25rem;
    letter-spacing: -0.00625rem;
    text-transform: none;
}

.form-box__text-top {
    margin-bottom: 2rem;
}

.form-box__social-login {
    margin-bottom: 3.25rem;
}
.form-box__social-login__button {    
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 12px;
}
.form-box__social-login__button:focus {
    outline: none;
}

.form-box__text-btm {
    margin-bottom: 1.875rem;    
}

.form-box__social-a {
    margin-right: 1rem;
}

.form-box__next-button-wrapper {
    margin-left: 9.1rem;
    margin-right: 9.1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.button__next-button--wide {
    width: 100%;
}
.form-box__password-requirements{
    margin-top: 1rem;
    line-height: 1.5rem;
}

@media screen and (min-width: 1681px) {
    .form-box {
        margin-left: 45%;
    }
    .form-box-content {
        width: 615px;
        margin-right: 6rem;
    }
    .form-box__next-button-wrapper {
        width: 615px;
        margin-right: 6rem;
    }
}

@media screen and (max-width: 1680px) {
    .form-box {
        margin-left: 615px;
    }
}

@media screen and (max-width: 1440px) {
    .form-box {
        margin-left: 415px;
    }
    .form-box-content {
        margin-left: 4rem;
        margin-right: 4rem;
        margin-top: 2rem;
    }
    .form-box__next-button-wrapper {
        margin-left: 4rem;
        margin-right: 4rem;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 960px) {
    /*styles that apply to all single column widths*/
    .form-box {
        margin-left: 0;
        min-width: unset;
        max-width: unset;
        min-height: unset;
        width: unset;
    }
    .form-box-content {
        margin-left: 2rem;
        margin-right: 2rem;
        margin-top: 2rem;
    }
    .form-box__title{
        margin-bottom: 1.125rem;
    }
    .form-box__text-top{
        margin-bottom: 1.25rem;
    }
    .form-box__social-login-a {
        margin-bottom: 2.5rem;
    }
    .form-box__text-bottom{
        margin-bottom: 1.25rem;
    }
    .form-box__next-button-wrapper {
        margin-left: 0rem;
        margin-right: 0rem;
        margin-top: 2rem;
    }
    /* label {
        padding-bottom: .5em;
    } */
}

@media screen and (max-width: 765px) {
    .form-box-content {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-top: 1.875rem;
    }
    .form-box__title {
        /* h2 */
        font-size: 2.25rem;
        line-height: 2.75rem;
        letter-spacing: -.0025rem;
    }
}

@media screen and (max-width: 660px) {
    /*the "375" styles -- start early at 660*/
}