@import '../../common/common.scss';

.video-section-wrapper {
  background: $gray-1
    linear-gradient(360deg, rgba(0, 0, 0, 0.6) 71.35%, rgba(0, 0, 0, 0) 88.96%);
}

.habitat-bg {
  background: $brand-blue-1
    linear-gradient(360deg, rgba(0, 0, 0, 0.6) 71.35%, rgba(0, 0, 0, 0) 88.96%) !important;
}

.container {
  width: calc(100% - 100px);
  margin: auto;
}

section.video-section {
  height: 800px;
  width: 100%;
  position: relative;
  //display: flex;
}

.video-section {
  // &__chat{
  //     flex-basis: 30%;
  //     position: relative !important;
  //     height: calc(800px / 100 * 80 );
  // }

  &__view {
    // position: relative;
    // flex-basis: 70%;
    height: 100%;
  }
}

#breadcrumbs {
  font-family: 'MaisonNeue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #ffffff;
  margin-top: 4rem;
  margin-bottom: 70px;
}

#title-bar {
  display: flex;
  color: white;
  #title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .sponsor-logo {
      max-width: 240px;
      img {
        width: 100%;
        height: 60px;
      }
    }

    .room {
      margin-left: 30px;
      white-space: nowrap;
      line-height: 1.8;
    }
  }
  #actions {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;

    .leave {
      position: relative;

      & .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: $gray-2;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        top: -2.3rem;
        left: -2rem;
        position: absolute;
        z-index: 1;
      }

      &:hover .tooltiptext {
        visibility: visible;
      }
    }

    div {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      height: 50px;
      width: 50px;
      margin-left: 15px;
      padding: 15px 0;
      text-align: center;
      line-height: 1;
      cursor: pointer;
      white-space: nowrap;
      font-size: 13px;

      &.bg-1 {
        position: relative;
        background: rgba(255, 0, 0, 0.6);

        &::after {
          content: 'Muted';
          position: absolute;
          bottom: -15px;
          left: 5px;

        }
      }
      &.bg-1-active {
        position: relative;
        background: rgba(0, 0, 0, 0.5);

        &::after {
          content: 'Audio on';
          position: absolute;
          bottom: -15px;
          left: 5px;

        }
      }

      &.bg-2 {
        position: relative;
        background: rgba(255, 0, 0, 0.6);

        &::after {
          content: 'Video Off';
          width: 60px;
          position: absolute;
          bottom: -15px;
          left: 0px;
        }
      }

      &.bg-2-active {
        position: relative;
        background: rgba(0, 0, 0, 0.5);

        &::after {
          content: 'Video On';
          width: 60px;
          position: absolute;
          bottom: -15px;
          left: 0px;
        }
      }

      &:not(.bg):hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
