.af-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    max-width: 90vw;
    max-height: 90vh;
    width: fit-content;
    overflow-y: auto;

    background: #fff;
    outline: none;
}
.af-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(32, 62, 104, 0.5);
    z-index: 200;
}
.af-container {
    flex: 1 2 150px;
    margin-right: 2rem;
    text-align: center;  
}
.af-container__avatar{
    margin-bottom: 1rem;  
    display: inline-block; 
}
.af-container__change {
    display: block;
    cursor: pointer;
}
@media screen and (max-width: 960px) {
    .af-modal {
        /* width: 100%;
        height: 100%; */
    }
}