/* 
Originally from: https://spotfund-widget.s3.amazonaws.com/code/absolute/v1.0/spotfundWidget.css

Any changed values have the original values commented
Any additions are noted with comments (prefix cw)

Given that this CSS will go through a pre-processor and auto-prefixer, some browser specific prefixes were removed
 */

.sfWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
  box-sizing: border-box;
}

@media only screen and (min-width: 760px) {
  .sfWrapper {
    padding: 10px 25px 25px;
  }
}

.sfBackgroundModal {
  background-color: #121212;
  height: 100vh;
  /* opacity: 0.5; */
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.sfIFrameWrapper {
  height: calc(((100vw - 6px) * (41 / 60)) + 310px);
  max-height: 610px;
  z-index: 999999;
  overflow: auto;
  width: 115vw;
  min-width: calc(312px / 0.85);
  max-width: 500px;
  -webkit-overflow-scrolling: touch;
  box-shadow: 10px 10px 15px -13px rgba(0, 0, 0, 0.8);
  /* -webkit-transform: scale(0.85); */
  transform: scale(0.85);
  overflow: hidden !important;
  /* cw - global site styles set box-sizing to border-box, but this element works best in content-box*/
  box-sizing: content-box;
}

/* extra small */
@media only screen and (max-device-width: 319px) {
  .sfIFrameWrapper {
    margin: -45px 0px -80px 0px;
  }
}

/* desktop */
@media only screen and (min-width: 668px) {
  .sfIFrameWrapper {
    /* margin: 0px 0px 0px 0px ;  */
    margin: 20px;

    /* cw - additions */
    transform: unset;
    height: 100%;
    width: 100%;
    max-width: 770px;
    min-width: 312px;
    /* 
    cw - 
    no matter the height, the widget will always show a scrollbar once a donate amount is selected and its modal opens.    
    */
    max-height: unset;
  }
}

/* old iphone */
@media only screen and (min-device-width: 320px) and (max-device-width: 667px) {
  .sfIFrameWrapper {
    margin: -45px 0px -80px 0px;
  }
}

/* iphone 6/7/8 */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-device-pixel-ratio: 2) {
  .sfIFrameWrapper {
    margin: -40px 0px 0px 0px;
  }
}

/* iphone x */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .sfIFrameWrapper {
    margin: 0px 0px 0px 0px;
  }
}

/* height resizer - resize width for shorter heights */
@media only screen and (max-height: 680px) and (min-width: 500px) {
  .sfIFrameWrapper {
    width: calc((115vh - (183px / 0.85)) * (0.843));
  }
  .sfIFrameWrapper {
    margin: -45px 0px 0px 0px;
  }
}

.sfIFrame {
  background-color: #ffffff;
  height: 100%;
  width: 100%;

  /* cw - additions */
  /* cw - global site styles set box-sizing to border-box, but this element works best in content-box*/
  box-sizing: content-box;
  border: none;
}

.sfCloseButton {
  align-self: flex-end;
  background-color: transparent;
  border-radius: 50%;
  border: 0;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  height: 45px;
  text-align: center;
  width: 45px;
  z-index: 999999;

  /* cw - additions */
  cursor: pointer;
}
