@import '../../common/common.scss';

.demand-videos {
  &__container {
    padding: 1.25rem 0;
  }

  &__header {
    //margin-bottom: 2rem;
  }

  &__menu-top {
    display: flex;
    margin-bottom: 1.25rem;

    & > *:not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__max-content {
    background-color: #f4f4f4;
  }

  &__content {
    max-width: 1921px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  &__cards {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    & > * {
      margin-bottom: 2rem;
    }
  }

  // &__sponsor-menu {
  //   background-color: $blue-6;

  //   .alice-carousel {
  //     &__dots-item {
  //       background-color: white !important;
  //     }

  //     &__dots {
  //       .__active {
  //         background-color: $gray-1 !important;
  //       }
  //     }
  //   }
  // }
}

@media screen and (min-width: 990px) {
  .demand-videos {
    &__container {
      padding: 2.25rem 0;
    }

    &__header {
    }

    &__breadcrum {
      margin-top: 2rem;
      padding-left: 5.25rem;
    }

    &__title {
      font-size: 36px;

      span {
        &::before {
          content: '';
          display: block;
        }
      }
    }

    &__content {
      flex-direction: row;
    }

    &__cards {
      flex-basis: 80%;
      padding-left: 0;
    }
  }
}

@media screen and (min-width: 1366px) {
  .demand-videos {
    &__cards {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      gap: 2rem;

      & > * {
        margin-bottom: 0rem;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .demand-videos {
    &__container {
      padding: 4.25rem 0;
    }

    &__breadcrum {
      padding-left: 9.25rem;
    }
  }
}

@media screen and (min-width: 1600px) {
  .demand-videos {
    &__cards {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    &__breadcrum {
      padding-left: 9.25rem;
    }
  }
}
