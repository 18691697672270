.pav-container {
  position: relative;
  z-index: 3;
  height: 100%;
}



.pav-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  margin-bottom: 1px;
  background-color: white;
}
.padded-list
{
  list-style: disc;
  margin-left: 20px;
}
.pav-header-twin {
  background-color: unset;
}
.pav-header_date-group {
  padding-left: 2.5rem;
  padding-top: 1.75rem;
  padding-bottom: 12px;
  flex: 1 1 auto;
  z-index: 2;
  background-color: #13819C;
  color: #ffffff;
  font-family: 'Neue Haas Grotesk Display';
  font-style: normal;
  font-weight: 700;
  
  /* prevent scrollbar interference */
  margin-right: 30px;
}

.pav-header_twin-left {
  /* background-color: white; */
  border-radius: 3px;
  cursor: pointer;
}
.pav-header_twin-right {
  cursor: pointer;
  border-radius: 3px;
  /* background-color: white; */
}
.pav-header_twin-unselected {
  /* opacity: 0.8; */
  background-color: #D1ECF3;
  color: #3B3B3B;
}
.pav-header_day {
  display: inline;
  margin-right: 0.75rem;
}
.pav-header_num-day {
  display: inline;
}
.pav-agenda-item_tpye.virtual
{
  background: #D13403;
  border-radius: 3px;
  color: #ffffff;
  max-width: 120px;
  padding: 5px 10px 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
}
.pav-agenda-item_tpye.in-person
{
  background: #F3F3F3;
  border-radius: 3px;
  color: #D13403;
  max-width: 120px;
  padding: 5px 10px 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
}

.pav-header_type-tag {
  background-color: white;
  padding-left: 2.5rem;
  padding-top: 48px;
  padding-bottom: 16px;
  padding-right: 16px;
  /* padding-left: 2.5rem;
    padding-right: 2.25rem;
    padding-top: 1.375rem;
    padding-bottom: 1.375rem; */
  text-align: right;
  position: sticky;
  top: 0;
  /* margin-bottom: 1px; */
  border-bottom: 1px solid black;
  font-weight: bold;
}

.pav-agenda-items-container-single {
  /* max-height: 90%; */
  max-height: 440px;
  overflow-y: auto;
  position: absolute;
  top: 0;
}

.pav-agenda-items-container-twin {
  max-height: 80%;
  overflow-y: scroll;
}

.pav-agenda-items-container-twin::-webkit-scrollbar{
  width: 5px;
  height: 5px;
  }

  .pav-agenda-items-container-twin::-webkit-scrollbar-thumb{
  background: #B3AFB3;
  border-radius: 10px;
  }
  .pav-agenda-items-container-twin::-webkit-scrollbar-thumb:hover{
  background: #B3AFB3;
  }
  .pav-agenda-items-container-twin::-webkit-scrollbar-track{
  background: #F0F0F0;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #F0F0F0;
  }

.pav-agenda-item {
  background-color: white;
  margin-bottom: 1px;
  padding-left: 2.5rem;
  padding-right: 2.25rem;
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
}
.pav-agenda-item--hidden {
  display: none !important;
}
.pav-od-item {
}
.pav-ls-item {
  display: flex;
  flex-direction: row;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 5px;

}
.pav-agenda-item_time {
  flex: 0 0 auto;
  width: 74px;
  padding-right: 1rem;
  text-align: center;
}
.pav-agenda-item_title-dsc-group {
}
.pav-agenda-item_title {
  margin-bottom: 0.75rem;
}
.pav-agenda-item_dsc {
}

@media screen and (max-width: 990px) {
  .pav-header_date-group {
    padding-left: 0.8rem;
    margin-right: 10px;
  }
  .pav-agenda-item
  {
    padding: 16px;
  }
  .pav-header_type-tag {
    display: flex;
    justify-content: center;
  }
  .pav-header-twin
  {
    justify-content: space-between;
  }
  .agenda-content
  {
    padding: 0;
  }
}
