.pe-container {

}
.pe-content {
    margin-top: 4.375rem;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4.375rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
.pe-content__header {
    /* h1 */
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 3rem /* 48px*/;
    line-height: 3.25rem /*52px*/;
    letter-spacing: -0.025rem /*-0.4px*/;
    margin-bottom: 1.25rem;
}
.pe-content__text {
    /* body2 */
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem /*16px*/;
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
}
.pe-content__profile {
    border-top: 1px solid #979797;
    margin-top: 1.875rem;
    padding-top: 1.875rem;
}
.pe-footer {
    margin-top: 1em;
    border-top: 1px solid #979797;
    padding-top: 1rem;
    /*  #E3E3E3 */
}
.pe-cancel-button {
    margin-top: 1rem;    
    display: inline-block;
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    
    letter-spacing: 0.1px;
    
    /* Gray 1 */
    
    color: #3B3B3B;
    opacity: 0.8;
}
.pe-save-button {
    float: right;
}