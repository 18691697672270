/* desktop first styles */
.container {
  height: 100%;
  width: 100%;
}

.sections-container {
}

.section {
  position: relative;
}

.--hide {
  display: none;
}

.home {
  background-color: #2b538b;
}

.about {
  background-color: #d4f2f3;
}
.speakers {
  background-color: var(--blue-6);
}
.sponsors {
  background-color: var(--gold-6);
}
.agenda {
  background-color: var(--red-6);
}
.support {
  background-color: var(--green-6);
}
.register {
  background-color: var(--green-1);
}

.auction {
}

.pre-event-nav-target {
  position: absolute;
  visibility: hidden;
}

@media screen and (max-width: 1440px) {
  /* mobile styles */
  .menu-margin-big {
    margin-top: 145px;
  }
}
