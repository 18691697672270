@import '../../common/common.scss';

.rwcp-container {
    overflow: hidden;
    /* height: 100%; */
    width: 100%;
}
.rwip-habitat-logo {
    height: 48px;
}
.rwcp-body {
    /* margin-top: 6rem;
    margin-left: 8.4375rem;
    margin-right: 9.6875rem; */
    height: 100%;
    display: flex;
    flex-direction: column;
}
.rwcp-body__header {   
    margin-bottom: 1.25rem;
    /* h1 */
    font-family: RecifeDisplayBook;
    font-style: normal;
    font-weight: normal;
    font-size: 3rem;
    line-height: 3.25rem;
    letter-spacing: -0.025rem;
    color: var(--gray-1);
}

.rwcp-body__text {
    /*body2*/
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem /*16px*/;
    line-height: 1.25rem;
    color: $gray-1;
}

@media screen and (max-width: 765px) {
    .rwcp-body {

    }
    .rwcp-body__header {
        margin-bottom: 1 rem;
        /* h2 */
        font-size: 2.25rem; /*36px*/
        line-height: 2.75rem; /*44px*/    
        letter-spacing: -.0025rem; /*-0.4px*/
    }
}